import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Loader } from '@components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { constant } from '@app/utils/constant';
import moment from "moment";
import InputMask from 'react-input-mask';
import UpdateAmountMdl from './modals/UpdateAmountMdl';
import AddNewCardMdl from './modals/AddNewCardMdl';
import AutoPayMdl from './modals/AutoPayMdl';
import { getAccountDetails, getPaymentForm, makePayment, addPaymentMethod } from '../../services/payment';


const Step2 = (props: any) => {
    const navigate = useNavigate();
    const formRef = useRef(null);
    const intervalIDRef = useRef(null);
    const todayDate = new Date();
    const [updateAmountModalShow, setUpdateAmountModalShow] = useState(false);
    const [addNewCardModalShow, setAddNewCardModalShow] = useState(false);
    const [enrollAutopayModalShow, setEnrollAutopayModalShow] = useState(false);
    const [isPaymentMethodAdded, SetPaymentMethodAdded] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [paymentFormUrl, setPaymentFormUrl] = useState('');
    const [loadScript, setLoadScript] = useState(false);
    const [ispayLoading, setPayLoading] = useState(false);
    const [updateManuallyPrice, setUpdateManuallyPrice] = useState(0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(-1);
    const [selectedCardForPay, setSelectedCardForPay] = useState({ 
        CardNumber: '',
        ExpiryDate: '',
        CardType: '',
        CustomerRefNumber: ''
    });
    const [enrollMsgShow, setEnrollMsgShow] = useState(false);
    const customerData = props.customerData; 
    const [initialValues, setInitialValues] = useState({
        OrderDate: '',
        LedgerNumber: '',
        Address: '',
        Email: '',
        Phone: '',
        Amount: 0,
        EnrollAutoPay: 'N',
        paymentType: 0
    });

    const [cardAdded, setCardAdded] = useState(false);

    const dateFormat = (date: any, format = "MM/DD/YYYY") => {
        //split year from date
        let yearVal = date.split('/')[0];

        if (yearVal.length == 2) {
            date = '20' + date;
        }

        return moment(date).isValid() ? moment(date).format(format) : date;
    }

    useEffect(() => {
        if(customerData.paymentMethods && customerData.paymentMethods.length > 0){ 
            SetPaymentMethodAdded(true);
        }
 
        setInitialValues({
            OrderDate: (customerData.InVoiceDate) ? dateFormat(customerData.InVoiceDate, 'YYYY-MM-DD') : '',
            LedgerNumber: (customerData.LedgerNumber) ? customerData.LedgerNumber : '',
            Address: (customerData.Address) ? customerData.Address : '',
            Email: (customerData.EmailAddress) ? customerData.EmailAddress : '',
            Phone: (customerData.PhoneNumber) ? customerData.PhoneNumber : '',
            Amount: (customerData.Amount) ? customerData.Amount : 0,
            EnrollAutoPay: 'N',
            paymentType: 0
        }); 
    }, [customerData]);


    const handleAmountChange = (value: number) => {
        handleChange({ target: { name: 'Amount', value: value } });
        setInitialValues({ ...initialValues, Amount: value });
    }

    const handleAutoPayCheck = (evt: any) => {
        if (evt.target.checked) {
            //setEnrollAutopayModalShow(true);
            setEnrollMsgShow(true);
            handleChange({ target: { name: 'EnrollAutoPay', value: evt.target.value } });
            setInitialValues({ ...initialValues, EnrollAutoPay: evt.target.value });
        } else {
            setEnrollMsgShow(false);
            handleChange({ target: { name: 'EnrollAutoPay', value: 'N' } });
            setInitialValues({ ...initialValues, EnrollAutoPay: 'N' });
        }
    }

    const handleAutoPayChange = (type: number) => {
        handleChange({ target: { name: 'paymentType', value: type } });
        setInitialValues({ ...initialValues, paymentType: type });
    }

    /* payment form script */ 
    const openPaymentForm = () => { 
        addScript(constant.Paymentech_js_Api_Url);

        setPayLoading(true);

        getPaymentForm().then(function (res) {
            if (res && typeof res.data !== "undefined") {
                const resp = res.data;
                if (resp.Acknowledge == 1 && resp.FullMessage != "") {
                    setPaymentFormUrl(resp.FullMessage);
                    
                    // callback function when saved card info from chase payment form
                    intervalIDRef.current = setInterval(() => {
                        saveCardInfo();
                    }, 1000);

                } else {
                    toast.error('Error occured on payment endpoint.please try again.');
                    return false;
                }
            }
            setPayLoading(false);
        }).catch(err => {
            console.log(err);
            setPayLoading(false);
            return false;
        });

        setAddNewCardModalShow(true);
    }

    const handlePayMdlClose = () => {
        setAddNewCardModalShow(false);
        setPaymentFormUrl('');
    }

    const addScript = (url: any) => {
        if (!loadScript) {
            var s = document.createElement('script');
            s.src = url;
            document.head.appendChild(s);

            var s1 = document.createElement('script');
            s1.src = "../assets/payment.js";
            document.head.appendChild(s1);

            setLoadScript(true);
        }
    }

    useEffect(() => { 
        return () => clearInterval(intervalIDRef.current);
    }, []);

    const saveCardInfo = () =>{
        let paymentUID = formRef.current.paymentUID.value;
        if(paymentUID != "" && !cardAdded){ 
            clearInterval(intervalIDRef.current); // clear timeout as we get Uid from chase payment form
 
            // save card info to as400
            setLoading(true);
            let postData = {
                paymentUid:paymentUID,
                LedgerNumber:initialValues.LedgerNumber
            };

            setCardAdded(true);
            formRef.current.paymentUID.value = '';
            addPaymentMethod(postData).then(function (res) { 
                if (res && typeof res.data !== "undefined") {
                    const resp = res.data; 
                    if (resp.Acknowledge == 1) {
                        refreshData(initialValues.LedgerNumber);
                    } else {
                        toast.error((resp.FullMessage) ? resp.FullMessage : 'Error occured on make Store Card Information endpoint.'); 
                    }
                }
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false);
                toast.error('Error occured on Store Card Information endpoint.please try again.');
                return false;
            });
        } 
    }
    
    let validationShape = {};

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object(validationShape),
        onSubmit: (values) => {
            const formData = { ...values, ...selectedCardForPay };
            submitPayment(formData);
        },
    });

    const submitPayment = (values: any) => {  
        if(values.CustomerRefNumber == ""){
            toast.error('Please select a card to complete payment.');
            return false;
        }

        if(parseFloat(values.Amount) > 0){
            let postData = values; 
            setLoading(true);

            makePayment(postData).then(function (res) { 
                if (res && typeof res.data !== "undefined") {
                    const resp = res.data; 
                    if (resp.Acknowledge == 1) {
                        navigate("/payment-thank-you");
                    } else {
                        toast.error((resp.FullMessage) ? resp.FullMessage : 'Error occured on make payment endpoint.'); 
                    }
                }
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false);
                toast.error('Error occured on make payment endpoint.please try again.');
                return false;
            });
        }else{
            toast.error('Amount is not valid.please try again.');
            return false;
        }  
    }
 
    const handlePaymentMethod = (row:any) =>{ 
        setSelectedCardForPay({
            CardNumber: row.Last4Digits,
            ExpiryDate: row.ExpiryDate,
            CardType: row.AccountType,
            CustomerRefNumber: row.CustomerRefNumber
        });
    }

    const refreshData = (LedgerNumber: any) => {
        // open success modal
        if (LedgerNumber != '') { 
            setLoading(true);
            getAccountDetails(values.LedgerNumber).then(function (res: any) {
                setLoading(false);
                if (typeof res.data !== 'undefined') {
                    const resp = res.data;
                     
                    if (resp.Acknowledge == 1) {
                        let manuallyPrice = 0; 
                        // Set customer info data to pass into step2 for payment form
                        let getAccountDetailsModel = (resp.OutputModel) ? resp.OutputModel : [];
                        let cardDetails = (resp.CardDetails.length > 0) ? resp.CardDetails : [];

                        if (getAccountDetailsModel) {
                            if(updateManuallyPrice > 0 ){
                                manuallyPrice = updateManuallyPrice
                           }else{
                                manuallyPrice = getAccountDetailsModel.LedgerBalance;
                           }
                            let responseParams = {
                                CustomerName: (getAccountDetailsModel.CustomerName) ? getAccountDetailsModel.CustomerName : "",
                                Address: (getAccountDetailsModel.Address) ? getAccountDetailsModel.Address : "",
                                PhoneNumber: (getAccountDetailsModel.PhoneNumber) ? getAccountDetailsModel.PhoneNumber : "",
                                EmailAddress: (getAccountDetailsModel.EmailAddress) ? getAccountDetailsModel.EmailAddress : "",
                                DivisionCode: (getAccountDetailsModel.DivisionCode) ? getAccountDetailsModel.DivisionCode : "",
                                Amount: manuallyPrice,
                                LedgerNumber: (getAccountDetailsModel.LedgerNumber) ? getAccountDetailsModel.LedgerNumber : "",
                                InVoiceDate: (getAccountDetailsModel.InVoiceDate) ? getAccountDetailsModel.InVoiceDate : "",
                                InVoiceTotal: (getAccountDetailsModel.InVoiceTotal) ? getAccountDetailsModel.InVoiceTotal : "",
                                InVoiceNumber: (getAccountDetailsModel.InVoiceNumber) ? getAccountDetailsModel.InVoiceNumber : ""
                            }

                            if(cardDetails && responseParams){
                                responseParams.paymentMethods = cardDetails;
                            }
                            
                            props.setCustomerData(responseParams); 
                        }   
                    } else {
                        console.log('Account Number not valid.please try again with correct one.');
                    }
                }
            }).catch(err => {
                console.log(err);
                setLoading(false); 
            });
        }
    }

 
    return (
        <>
            <section className="payment_summary_blk">
                <div className="payment_summary_content">
                    <h1>Welcome, <span>{(props.customerData.CustomerName) ? props.customerData.CustomerName : ''}</span></h1>
                    <form onSubmit={handleSubmit} ref={formRef}>
                        <div className='order_information_blk order_info_blk'>
                            <h3 className='blk_title'>Order Information</h3>
                            <div className='order_info'>
                                <ul className='d-flex'>
                                    <li>
                                        <span className='item_val'>
                                            {(props.customerData.InVoiceDate) ? dateFormat(props.customerData.InVoiceDate) : ''}
                                        </span>
                                        <span className='item_lbl'>Order Date</span>
                                    </li>
                                    <li>
                                        <span className='item_val'>
                                            {(props.customerData.LedgerNumber) ? props.customerData.LedgerNumber : ''}
                                        </span>
                                        <span className='item_lbl'>Ledger Number</span>
                                    </li>
                                    <li><span className='payable_amount_blk'><span className='item_val'>${initialValues.Amount}</span><Button className='edit_btn' onClick={() => setUpdateAmountModalShow(true)}></Button></span><span className='item_lbl'>Payment Amount</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className='order_address_blk order_info_blk'>
                            <h3 className='blk_title'>Address</h3>
                            <div className='address_blk'>
                                <p>{(props.customerData.Address) ? props.customerData.Address : ''}</p>
                            </div>
                            <h3 className='blk_title'>Personal Information</h3>
                            <div className='personal_info'>
                                <ul className='d-flex'>
                                    <li>
                                        <Form.Group>
                                            <Form.Label>Phone Number<span>*</span></Form.Label>
                                            <InputMask
                                                mask="999-999-9999"
                                                placeholder="Phone Number"
                                                id="Phone"
                                                name="Phone"
                                                value={values.Phone}
                                                onChange={handleChange}
                                            >
                                                {inputProps => <Form.Control {...inputProps} readonly="true" />}
                                            </InputMask>
                                        </Form.Group>
                                    </li>
                                    <li>
                                        <Form.Group>
                                            <Form.Label>Email Address<span>*</span></Form.Label>
                                            <Form.Control type="email" placeholder="Email" id="Email" name="Email" value={values.Email} onChange={handleChange} readOnly />
                                        </Form.Group>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='saved_card_blk order_info_blk'>
                            <h3 className='blk_title'>Saved Payment Method</h3>
                            <div className='saved_card_list'>
                                <ul>
                                     
                                {( props.customerData.paymentMethods && props.customerData.paymentMethods.length > 0) && (props.customerData.paymentMethods.map((row:any, podidx:number) => {
                                    let image = 'img/american_express.jpg';
                                    let cardType = 'AMERICAN EXPRESS';
                                    if (row.AccountType) {
                                        if(row.AccountType == 'M'){
                                            image = 'img/master_card.jpg';
                                            cardType= 'MASTER CARD';
                                        }else if(row.AccountType == 'V'){
                                            image = 'img/visa_card.jpg';
                                            cardType= 'VISA CARD';
                                        }
                                        else if(row.AccountType == 'D'){
                                            image = 'img/discover_icon.jpg';
                                            cardType= 'DISCOVER';
                                        }
                                    }

                                    return (
                                        <li
                                            key={podidx} 
                                            className={(selectedPaymentMethod == podidx) ? "active" : ""}
                                            onClick={()=>{handlePaymentMethod(row);setSelectedPaymentMethod(podidx);}}
                                        >
                                            <span>{cardType + ' ...ending ' + row.Last4Digits}</span>
                                            <img src={`/${image}`} alt={cardType} />
                                        </li> 
                                    );
                                })
                                )} 
                                </ul>
                            </div>
                            <div className='add_card_btn_blk'>
                                <input
                                    type="hidden"
                                    id="paymentUID"
                                    name="paymentUID"
                                />
                                <Button className='red_outline_btn' onClick={() => openPaymentForm()}>Add a New Credit Card</Button> 
                            </div>
                            <div className='autopay_blk'>  
                                {['checkbox'].map((type) => (
                                    <div key={`inline-${type}`} className="autopay_checkbox">
                                        <Form.Check inline label="Enroll for AutoPay" name="EnrollAutoPay" value="Y" type={type} id={`inline-${type}-1`} onChange={(e) => handleAutoPayCheck(e)} disabled={(selectedPaymentMethod >= 0)? false : true} />
                                    </div>
                                ))}
                            </div>

                            { (enrollMsgShow) && 
                            <div className='selected_card_blk'>
                                <div className="alert alert-success" role="alert">
                                    <p>I authorize Slomin's, Inc. to automatically charge either the credit card or checking account shown above for all payments due on this account. This automatic charge shall continue until i decide to discontinue this payment option. If i decide to discontinue this option, i will give Slomin's thirty(30) days notice. I understand that Slomin's reserves the right, upon written notification to me, to terminate this automatic payment option, and/or my participation therein at anytime. My participation is subject to Slomin's continuing approval.</p>
                                </div>
                            </div>
                            }

                            {/* 
                            <div className='selected_card_blk'>
                                <div className="alert alert-primary" role="alert">
                                    <p>Selected Autopay payment method: <span className="selected_card"><strong>VISA CARD...ending 1111</strong></span></p>
                                </div>
                            </div>
                            */}
                        </div>
                        <Button className='red_btn' type="submit">Complete Payment</Button>
                    </form>
                </div>
                <Loader isLoading={isLoading} />
            </section>

            {/* Update Payable Amount Popup*/}
            <UpdateAmountMdl updateAmountModalShow={updateAmountModalShow} setUpdateAmountModalShow={setUpdateAmountModalShow} handleAmountChange={handleAmountChange} handleChange={handleChange} setUpdateManuallyPrice={setUpdateManuallyPrice} />

            {/* Add New Card Popup*/}
            <AddNewCardMdl addNewCardModalShow={addNewCardModalShow} setAddNewCardModalShow={setAddNewCardModalShow} handleChange={handleChange} handlePayMdlClose={handlePayMdlClose} ispayLoading={ispayLoading} paymentFormUrl={paymentFormUrl} />

            {/*Autopay Popup*/}
            <AutoPayMdl enrollAutopayModalShow={enrollAutopayModalShow} setEnrollAutopayModalShow={setEnrollAutopayModalShow} handleAutoPayChange={handleAutoPayChange} handleChange={handleChange} />
        </>
    );
};

export default Step2;